import React, {Suspense} from 'react';
import {RoutePermittedRole} from '@crema/constants/AppEnums';
import AppLoader from '@crema/components/AppLoader';
const UserProspectData = React.lazy(
  () => import('../../../modules/dashboards/Prospects'),
);
const UserAccountsData = React.lazy(
  () => import('../../../modules/dashboards/Accounts'),
);

export const dashBoardConfigs = [
  {
    permittedRole: [RoutePermittedRole.User, RoutePermittedRole.Admin],
    path: '/prospects/:name/:id',
    element: (
      <Suspense fallback={<AppLoader />}>
        <UserProspectData />
      </Suspense>
    ),
  },
  {
    permittedRole: [RoutePermittedRole.User, RoutePermittedRole.Admin],
    path: '/accounts/:name/:id',
    element: (
      <Suspense fallback={<AppLoader />}>
        <UserAccountsData />
      </Suspense>
    ),
  },
];
