/* eslint-disable prettier/prettier */
import { createReducer } from '@reduxjs/toolkit';
import {
  AllUsersDataList,
  UserProspectsListing,
  UserAccountsListing,
} from './ActionTypes/Dashboard';

const initialState: {
  allUsersData: any | object;
  prospectList: any | object;
  accountsList: any | object;
} = {
  allUsersData: [],
  prospectList: [],
  accountsList: [],
};

const dashboardReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(AllUsersDataList, (state: any, action: any) => {
      state.allUsersData = action.payload;
    })
    .addCase(UserProspectsListing, (state: any, action: any) => {
      state.prospectList = action.payload;
    })
    .addCase(UserAccountsListing, (state: any, action: any) => {
      state.accountsList = action.payload;
    });
});

export default dashboardReducer;
