import React, {Suspense} from 'react';
import {RoutePermittedRole} from '@crema/constants/AppEnums';
import AppLoader from '@crema/components/AppLoader';
const Account = React.lazy(() => import('../../../modules/account/MyProfile'));

export const accountPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.User,
    path: '/my_account',
    element: (
      <Suspense fallback={<AppLoader />}>
        <Account />
      </Suspense>
    ),
  },
];
