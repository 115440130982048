/* eslint-disable prettier/prettier */
import {
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  SHOW_DELETE_MODAL,
  SHOW_MODAL,
} from '@crema/types/actions/Common.action';
import { createReducer } from '@reduxjs/toolkit';
import { CommonType } from '@crema/types/models/Common';
import { FetchErrorAction, ShowMsgAction, ShowErrorMessage } from './ActionTypes/Common';

const INIT_STATE: CommonType = {
  error: '',
  loading: false,
  message: '',
  showDeleteModal: false,
  showModal: false,
};

const commonReducer = createReducer(INIT_STATE, (builder) => {
  builder
    .addCase(FETCH_START, (state) => {
      state.error = '';
      state.message = '';
      state.loading = true;
    })
    .addCase(FETCH_SUCCESS, (state) => {
      state.error = '';
      state.message = '';
      state.loading = false;
    })
    .addCase(ShowMsgAction, (state, action) => {
      state.error = '';
      state.message = action.payload;
      state.loading = false;
    })
    .addCase(ShowErrorMessage, (state, action) => {
      state.error = action.payload;
      state.message = '';
      state.loading = false;
    })
    .addCase(FetchErrorAction, (state, action) => {
      state.error = action.payload;
      state.message = '';
      state.loading = false;
    })
    .addCase(HIDE_MESSAGE, (state) => {
      state.error = '';
      state.message = '';
      state.loading = false;
    })
    .
    addCase(SHOW_DELETE_MODAL, (state: any, action: any) => {
      state.showDeleteModal = action.payload;
    })
    .addCase(SHOW_MODAL, (state: any, action: any) => {
      state.showModal = action.payload;
    });
});

export default commonReducer;
