// action strings
export const FETCH_START = 'FETCH_START';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_ERROR = 'FETCH_ERROR';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const SHOW_DELETE_MODAL = 'SHOW_DELETE_MODAL';
export const SHOW_ERROR_MESSAGE = 'SHOW_ERROR_MESSAGE';
export const SHOW_MODAL = 'SHOW_MODAL';

export type FetchStartAction = {
  type: typeof FETCH_START;
};

export type FetchSuccessAction = {
  type: typeof FETCH_SUCCESS;
};

export type FetchErrorAction = {
  type: typeof FETCH_ERROR;
  payload: string;
};

export type ShowMessageAction = {
  type: typeof SHOW_MESSAGE;
  payload: string;
};

export type HideMessageAction = {
  type: typeof HIDE_MESSAGE;
};

export type ShowDeleteModal = {
  type: typeof SHOW_DELETE_MODAL;
};

export type ShowErrorMessageAction = {
  type: typeof SHOW_ERROR_MESSAGE;
  payload: string;
};

export type ShowModal = {
  type: typeof SHOW_MODAL;
  payload: boolean;
};

export type CommonActionTypes =
  | FetchStartAction
  | FetchSuccessAction
  | FetchErrorAction
  | ShowMessageAction
  | HideMessageAction
  | ShowDeleteModal
  | ShowErrorMessageAction
  | ShowModal;
