/* eslint-disable prettier/prettier */
import {
  SET_ALL_USERS_DATA,
  SET_PROSPECT_DATA,
  SET_ACCOUNTS_DATA,
} from '@crema/types/actions/Dashboard.action';

import { createAction } from '@reduxjs/toolkit';

export const AllUsersDataList = createAction<any>(SET_ALL_USERS_DATA);
export const UserProspectsListing = createAction<any>(SET_PROSPECT_DATA);
export const UserAccountsListing = createAction<any>(SET_ACCOUNTS_DATA);