export const getUserFromJwtAuth = (user: any) => {
  if (user)
    return {
      uid: user._id,
      name: user.name,
      profileImage: user.profilePic,
      accountCount: user.accountCount,
      accountsLeft: user.accountsLeft,
      createdAt: user.createdAt,
      email: user.email,
      isSubscribed: user.isSubscribed,
      password: user?.password,
      prospectCount: user.prospectCount,
      type: user.type,
      updatedAt: user.updatedAt,
      verified: user.verified,
      version: user.__v,
    };
  return user;
};
