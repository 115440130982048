import React, {useEffect, useState} from 'react';
// import AppSidebar from './AppSidebar';
import AppContentView from '../../AppContentView';
// import AppThemeSetting from '../../AppThemeSetting';
import AppHeader from './AppHeader';
import clsx from 'clsx';
import Hidden from '@mui/material/Hidden';
import Box from '@mui/material/Box';
import BitBucketWrapper from './BitBucketWrapper';
import {LayoutType} from '@crema/constants/AppEnums';
import {useLayoutContext} from '@crema/context/AppContextProvider/LayoutContextProvider';
import BitBucketContainer from './BitBucketContainer';
import {useLocation} from 'react-router-dom';
import {RouterConfigData} from '@crema/types/models/Apps';

type Props = {
  routes: React.ReactElement | null;
  routesConfig: RouterConfigData[];
};
const BitBucket = ({routes, routesConfig}: Props) => {
  const {layoutType} = useLayoutContext();
  const {pathname} = useLocation();
  const [isNavCollapsed, setNavCollapsed] = useState(false);
  const toggleNavCollapsed = () => {
    setNavCollapsed(!isNavCollapsed);
  };
  useEffect(() => {
    if (isNavCollapsed) setNavCollapsed(!isNavCollapsed);
  }, [pathname]);

  // app main content file

  return (
    <BitBucketContainer
      className={clsx({
        boxedLayout: layoutType === LayoutType.BOXED,
        framedLayout: layoutType === LayoutType.FRAMED,
      })}
    >
      <BitBucketWrapper
        className={clsx('bitBucketWrapper', {
          bitBucketCollapsed: isNavCollapsed,
        })}
      >
        {/* <Hidden lgUp>
          <AppHeader toggleNavCollapsed={toggleNavCollapsed} />
        </Hidden> */}
        {/* <AppSidebar
          routesConfig={routesConfig}
          isNavCollapsed={isNavCollapsed}
          toggleNavCollapsed={toggleNavCollapsed}
        /> */}
        <Box className='mainContent'>
          <AppContentView routes={routes} />
        </Box>
        {/* <AppThemeSetting /> */}
      </BitBucketWrapper>
    </BitBucketContainer>
  );
};

export default BitBucket;
