import React from 'react';
import AppSuspense from '../AppSuspense';
// import AppFooter from '../AppLayout/components/AppFooter';
import AppErrorBoundary from '../AppErrorBoundary';
import Box from '@mui/material/Box';
import AppContentViewWrapper from './AppContentViewWrapper';
import {SxProps} from '@mui/system';

type AppContentViewProps = {
  sxStyle?: SxProps;
  routes: React.ReactElement | any;
};

const AppContentView: React.FC<AppContentViewProps> = ({routes, sxStyle}) => {
  // const {pathname} = useLocation();

  // const urlArray = ['/signin', '/forget-password', '/reset-password'];

  return (
    <AppContentViewWrapper>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          backgroundColor: 'white',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
          mt: 2,
          // p:
          //   urlArray.includes(pathname) ||
          //   pathname?.startsWith('/accounts') ||
          //   pathname.startsWith('/prosects')
          //     ? 0
          //     : {xs: 3.5, sm: 4, md: 4.5, lg: 5, xl: 5.5, xxl: 6},
          ...sxStyle,
        }}
        className='app-content'
      >
        <AppSuspense>
          <AppErrorBoundary>{routes}</AppErrorBoundary>
        </AppSuspense>
      </Box>
    </AppContentViewWrapper>
  );
};

export default AppContentView;
